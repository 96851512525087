export default [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      resource: 'home',
      action: 'read',
      pageTitle: 'Comunicados',
      breadcrumb: [
        {
          text: 'Principal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/pages/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: '*',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: '*',
    },
  },
]
