/* eslint-disable no-param-reassign */
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.request.use(
  config => {
    config = {
      showToast: false,
      ...(config || {}),
    }
    return config
  },
)
axiosIns.interceptors.response.use(response => {
  if (response.config.showToast) {
    Vue.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: 'Sucesso',
        icon: 'CheckIcon',
        variant: 'success',
        text: response.data.message,
      },
    })
  }
  return response
}, error => Promise.reject(error))
Vue.prototype.$http = axiosIns

export default axiosIns
