export default [
  {
    path: '/adm/communicate/list',
    name: 'adm-communicate-list',
    component: () => import('@/views/adm/communicate-list.vue'),
    meta: {
      resource: 'communicate',
      action: 'read',
      pageTitle: 'Listagem de comunicados',
    },
  },
  {
    path: '/adm/communicate/new',
    name: 'adm-communincate-new',
    component: () => import('@/views/adm/communicate-new.vue'),
    meta: {
      resource: 'communicate',
      action: 'create',
      pageTitle: 'Novo Comunicado',
    },
  },
]
