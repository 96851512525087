import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
  if (route.meta.resource === '*') {
    return true
  }
  return ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined
