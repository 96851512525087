export default [
  {
    path: '/prosai',
    name: 'prosai',
    component: () => import('@/views/prosai/login.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/prosai/painel',
    name: 'prosai-painel',
    component: () => import('@/views/prosai/painel.vue'),
    meta: {
      resource: 'prosai-external',
      action: 'read',
    },
  },
]
