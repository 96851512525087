/* eslint-disable */

import Vue from 'vue'
import VueRouter from 'vue-router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'
import authentication from './routes/authentication'
import users from './routes/users'
import permissions from './routes/permissions'
import panels from './routes/panels'
import adm from './routes/adm'
import prosai from './routes/prosai'
import { getUserData } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'panel' } },
    ...pages,
    ...panels,
    ...authentication,
    ...permissions,
    ...users,
    ...adm,
    ...prosai,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const userData = getUserData()
let external = null
if(userData)
  external = userData.ability.find((item) => item.subject === "prosai-external");

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {

  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    Vue.$toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: 'Sem permissão de acesso',
        icon: "XIcon",
        variant: "danger",
        text: 'Você não possui permissão para acessar esta página. verifique com TI.',
      },
    })

    if(external)
      return next({ name: 'prosai-painel' })
    else
      return next({ name: 'panel' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next(getHomeRouteForLoggedInUser())
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router