/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable object-curly-newline */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin, IconsPlugin, BootstrapVue } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import VueCompositionAPI from '@vue/composition-api'
import i18n from '@/libs/i18n'
import VueEditor from 'vue2-editor'
import App from './App.vue'
import store from './store'
import router from './router'
import { localize } from 'vee-validate'

import './global-components'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/acl'

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)
Vue.use(VueEditor)

Vue.mixin({
  created() {
    this.$user = getUserData()
  },
})

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

localize('pt')

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
